.desktop-only-container {
  display: none;
}

.home-container {
  width: 100%;
  height: 100%;
  padding: 0px 40px;
  background-color: rgb(0, 0, 139);
  overflow: hidden;
  color: white;
}

.home-header-container {
  height: 80px;
}

.home-brand-label-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.home-brand-label-container h1 {
  font-weight: bold;
  color: white;
}

.home-signout-button-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.home-signout-button {
  background: white;
  border: 1px solid white;
  color: rgb(0, 0, 139);
  padding: 10px;
}

.home-content-container {
  height: 100%;
  width: 100%;
}

.home-content-side-nav {
  max-height: 90%;
}

.home-content-mobile-side-nav {
  display: none;
}

.nav-item {
  padding: 10px 0px;
}

.nav-link {
  padding: 0px;
  color: white;
  font-size: 16px;
}

.nav-link:hover {
  color: gold;
}

.nav-link-active {
  font-weight: bold;
  font-size: 18px;
}

.home-content-type-container {
  height: 90%;
  background-color: white;
}

.report-content-container p {
  color: black;
}

@media screen and (max-width: 1400px) {
  .home-container {
    overflow-y: scroll;
  }

  .home-content-side-nav {
    display: none;
  }

  .home-content-mobile-side-nav {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1300px) {
  .home-header-container {
    height: 100px;
  }

  .home-content-type-container {
    max-height: 100%;
  }
}
