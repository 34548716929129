.account-settings-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.account-settings-container h1 {
  padding-bottom: 10px;
}

.account-settings-container p {
  padding-bottom: 10px;
}

.current-info-container p {
  color: rgb(0, 0, 139);
}
