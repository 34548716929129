.auth-view-container {
  height: 100%;
  width: 100%;
}

.auth-view-content-container {
  height: 100vh;
  /* overflow-y: scroll; */
}

.auth-view-background {
  height: 100vh;
  background-color: rgb(0, 0, 139);
}

.auth-view-header {
  padding: 100px 100px 0px 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.auth-view-header h1 {
  color: rgb(0, 0, 139);
  font-size: 38px;
  font-weight: 600;
}

.auth-view-content {
  padding: 0px 100px;
}

.auth-view-content-header {
  padding: 25px 0px;
}

.auth-view-content-header h2 {
  font-size: 28px;
  font-weight: bold;
}

.auth-view-content-form-container {
  width: 100%;
}

.auth-view-form {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
}

.auth-view-form-input {
  width: 100%;
  margin-bottom: 50px;
  padding-bottom: 5px;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  color: rgb(0, 0, 139);
}

.auth-view-form-input:hover,
.auth-view-form-input:focus {
  border-bottom: 1px solid rgb(0, 0, 139);
}

@media screen and (max-width: 767.98px) {
  .auth-view-background {
    display: none;
  }
  .auth-view-header {
    padding: 100px 50px 0px 50px;
  }
  .auth-view-content {
    padding: 0px 50px;
  }
}
