.planner-header {
  margin-bottom: 25px !important;
}

.planner-header p {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.ad-inquiry-text {
  margin-bottom: 25px;
}

.label-input-container label {
  color: black;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

.label-input-container .pp-form-select {
  margin-bottom: 40px;
}

.generate-action-button {
  width: 100% !important;
  margin-bottom: 10px;
}

.report-action-button {
  width: 100% !important;
  margin-top: 25px;
}

.download-buttons-row {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.summary-download-button {
  width: 25% !important;
}

@media screen and (max-width: 1400px) {
  .summary-download-button {
    width: 100% !important;
  }
}
