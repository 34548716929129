html {
  scroll-behavior: smooth;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0px;
  padding: 0px;
}

#root {
  height: 100%;
  width: 100%;
}

.main {
  height: 100%;
  width: 100%;
}

.row {
  margin: 0px;
  padding: 0px;
}

.col-12,
.col-11,
.col-10,
.col-6,
.col-4,
.col-3,
.col-2,
.col-1 {
  margin: 0px;
  padding: 0px;
}

input:focus,
select:focus {
  outline: none;
}

.common-button {
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 25px;
  background: rgb(0, 0, 139);
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 25px;
}

.common-button:hover {
  background: transparent;
  border: 1px solid rgb(0, 0, 139);
  color: rgb(0, 0, 139);
}

.common-home-content-container {
  max-height: 100%;
  padding: 10px 20px;
  overflow-y: auto;
}

.common-home-content-header-label {
  color: rgb(0, 0, 139);
  font-weight: 600;
  font-size: 36px;
}

.common-home-section-header-label {
  margin: 0px;
  color: rgb(0, 0, 139);
  font-weight: 400;
  font-size: 32px;
}

#common-preview-card-container {
  /* cursor: pointer; */
  margin: 0px 5px;
}

/* #common-preview-card-container:hover {
  cursor: pointer;
} */

.common-preview-card-body h1 {
  font-size: 24px;
  font-weight: bold;
  color: rgb(0, 0, 139);
}

.common-preview-card-body h2 {
  font-size: 20px;
  font-weight: 600;
  color: rgb(0, 0, 139);
}

.common-preview-card-body p {
  color: rgb(0, 0, 139) !important;
}

.common-update-form {
  margin: 20px 0px;
}

.common-update-form-input {
  width: 25%;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  color: rgb(0, 0, 139);
}

.common-update-form-input:hover,
.common-update-form-input:focus {
  border-bottom: 1px solid rgb(0, 0, 139);
}

.common-update-form-button {
  width: 25%;
  padding: 20px 0px;
  background: transparent;
  border: 1px solid rgb(0, 0, 139);
  color: rgb(0, 0, 139);
  font-size: 20px;
  font-weight: bold;
}

.common-update-form-button:hover {
  background: rgb(0, 0, 139);
  color: white;
}

.common-filter-info-container {
  padding-bottom: 10px;
}

.common-filter-info-container p {
  font-size: 18px;
  font-weight: 400;
  color: rgb(0, 0, 139);
}

.common-filter-select {
  width: 33.33%;
}

.payment-source-form-container {
  height: 100%;
  width: 100%;
}

.will-not-charge-you-label {
  font-size: 24px;
  font-weight: bold;
}

.will-not-charge-you-desc {
  margin-bottom: 25px;
}

.positioned-form-input {
  margin-bottom: 20px !important;
  width: 100% !important;
}

#positioned-form-action-button {
  margin-top: auto;
}

#positioned-form-action-button {
  width: 100% !important;
}

.report-content-actions {
  margin-bottom: 50px !important;
}

.planner-action-button {
  width: 50% !important;
}

.planner-action-button-full-width {
  width: 100% !important;
}

@media screen and (max-width: 1300px) {
  .common-filter-select {
    width: 100%;
  }
  .common-update-form-input {
    width: 100%;
  }
  .common-update-form-button {
    width: 100%;
  }
  #common-preview-card-container {
    margin-bottom: 20px;
  }
}
