.history-list-container {
  display: flex;
  justify-content: space-around;
}

.history-list-container .total-found {
  color: black;
  font-size: 24px;
  font-weight: bold;
  padding: 0px;
  margin-bottom: 20px;
}

.plan-card-container {
  margin-bottom: 20px !important;
}

.plan-card-container:hover {
  cursor: pointer;
}

.load-buttons-container {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-button {
  background: transparent;
  border: 1px solid rgb(0, 0, 139);
  padding: 10px 25px;
  margin: 0px 10px;
  color: rgb(0, 0, 139);
}

.load-button:hover {
  background-color: rgb(0, 0, 139);
  color: white;
}

.disabled-load-button {
  background: transparent;
  border: 1px solid grey;
  padding: 10px 25px;
  margin: 0px 10px;
  color: grey;
}
