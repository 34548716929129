.report-content-container {
  overflow-y: auto;
}

.report-content-container h1 {
  color: black;
  font-weight: bold;
  font-size: 36px;
}

.plan-heading-label {
  margin-bottom: 20px;
  text-align: center;
}

.plan-location-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-location-image {
  width: 30%;
  height: auto;
  margin-bottom: 20px;
}

.recommendation-content-container {
  padding: 20px;
  margin-bottom: 40px;
  background-color: rgb(0, 0, 139);
  border-radius: 20px;
}

.recommendation-content-section .day {
  color: white;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.recommendation-activity-section {
  background-color: rgb(168, 168, 255);
  color: rgb(0, 0, 139) !important;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow-wrap: break-word;
}

.recommendation-activity-section p {
  color: rgb(0, 0, 139) !important;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.recommendation-activity-section a {
  color: rgb(0, 0, 139);
}

.total-estimated-cost-container p {
  padding-left: 20px;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
}

.review-buttons-container {
  margin-bottom: 50px;
  color: black;
}

.review-buttons-container .like-button {
  cursor: pointer;
  margin-left: 25px;
}

.review-buttons-container .dislike-button {
  cursor: pointer;
  margin-left: 50px;
}
